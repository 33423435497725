<template>
  <v-dialog
    :value="value"
    width="90%"
    max-width="1900px"
    persistent>
    <v-snackbar
      top
      v-model="cancelGroupAlert"
      content-class="pa-0"
      color="warning"
      :timeout="-1">
      <v-alert
        v-model="cancelGroupAlert"
        dense
        class="my-0"
        border="left"
        prominent
        type="warning"
        :retain-focus="true">
        Cancel creating this promo item group?
        <span>
          <v-btn
            outlined
            @click="deleteGroup"
            :loading="deleting"
            :disabled="deleting"
            class="mx-4">
            Yes
          </v-btn>
          <v-btn
            @click="cancelGroupAlert = false"
            :disabled="deleting">
            No
          </v-btn>
        </span>
      </v-alert>
    </v-snackbar>
    <v-card flat>
      <v-toolbar flat color="accent" class="white--text">
        <v-toolbar-title>
         {{ currentGroup ? 'Item Search' : 'New Item Group' }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col cols="3">
        <!--  <v-text-field
            v-show="currentGroup"
            v-model="filter"
            label="Search Items"
            prepend-inner-icon="mdi-magnify"
            background-color="input"
            outlined
            single-line
            clearable
            @click:clear="filter = ''"
            dense
            hide-details>
          </v-text-field> -->
        </v-col>
        <v-card-actions class="px-0">
          <v-btn
            icon
            dark
            text
            @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-toolbar>
      <ItemGroupEdit
        ref="groupEdit"
        :itemGroup="currentGroup"
        :promo_type_fields="promo_type_fields"
        :promo_type_constant="promo_type_constant"
        :promo="promo"
        :readOnlyPromo="readOnlyPromo"
        @saved="groupSaved">
      </ItemGroupEdit>
     <v-container fluid class="ma-0 pa-0">
        <div class="modal-content-container">
          <v-overlay
            :value="!currentGroup"
            :absolute="absolute">
            <v-row align="center">
              <v-btn fab x-small color="white" class="mr-2">
                <v-icon color="orange" x-large>
                  mdi-alert-circle
                </v-icon>
              </v-btn>
              <h3>Save Group Details to Add Items</h3>
            </v-row>
          </v-overlay>
          <div class="modal-column">
            <BulkItemSearch
              v-model="bulkSearchPayload"
              :source_ids="item_sources"
              :vmc_sources="vmc_sources">
            </BulkItemSearch>
            <div style="display: grid" class="mt-auto mb-0">
              <div
                v-if="addedItems.length > 0" 
                style="display: grid; grid-auto-flow: column"
                class="mb-2">
                <v-subheader class="ma-0">
                  Items selected: {{ uniqueItems.length }}
                </v-subheader>
                <v-btn
                  class="my-auto mr-2"
                  small
                  :disabled="loading"
                  @click="clearItems">
                  Clear
                </v-btn>
              </div>
              <v-btn v-if="!readOnlyPromo"
                class="mt-auto mb-2 mx-2"
                color="primary"
                :loading="loading"
                :disabled="loading || addedItems.length === 0"
                @click="addItems">
                Add Items
              </v-btn>
            </div>
          </div>
          <ItemSearchTable
            ref="itemSearch"
            :item_group="currentGroup"
            :item_search="filter"
            :item_sources="item_sources"
            :panel_height="panel_height"
            :promoItems="promoItems"
            :addedItems="addedItems"
            :vendorIds="vendorIds"
            :bulkSearchPayload="bulkSearchPayload"
            :promo="promo"
            :readOnlyPromo="readOnlyPromo"
            @selected="itemSelected"
            @updateAdded="updateAddedItems"
            @updateLists="updateLists">
          </ItemSearchTable>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import PromoItem from '@/axios/promotion-item-endpoint'
import PromoItemGroup from '@/axios/promotion-item-group-endpoint'
// Components
import ItemSearchTable from '@/components/items/itemSearchTable.vue'
import BulkItemSearch from '@/components/items/BulkItemSearch.vue'
import ItemGroupEdit from '@/components/items/ItemGroupEdit.vue'
// Mixins
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      filter: '',
      bulkSearchPayload: null,
      updateItems: false,
      panel_height: 350,
      loading: false,
      absolute: true,
      currentGroup: null,
      addedItems: [],
      cancelGroupAlert: false,
      deleting: false
    }
  },
  name: 'ItemSearchModal',
  components: { ItemSearchTable, ItemGroupEdit, BulkItemSearch },
  mixins: [displayAlert, utils, userAccess],
  props: {
    value: Boolean,
    itemGroup: Object,
    item_sources: Array,
    vmc_sources: Array,
    vendorIds: Array,
    promo: Object,
    promo_type_fields: Array,
    promo_type_constant: String,
    promoItems: Array,
    isAccrualPromo: Boolean,
    readOnlyPromo: {
      type: Boolean,
    }
  },
  created () {
    if (this.itemGroup) {
      this.currentGroup = { ...this.itemGroup }
    }
  },
  computed: {
    groupItems () {
      return this.promoItems.filter(promoItem => {
        return promoItem?.promo_item_group?.id === this.currentGroup?.id
      })
    },
    uniqueItems () {
      if (this.addedItems.length > 0) {
        const descs = this.addedItems.map(item => item.description)
        return Array.from(new Set([...descs]))
      }
      return []
    }
  },
  methods: {
    clearItems () {
      this.addedItems = []
      this.$refs.itemSearch.initItems()
    },
    onClose () {
      if (this.currentGroup && this.groupItems.length === 0) {
        const message = "Please click 'Add Items' to continue"
        return (this.addedItems.length > 0) 
          ? this.emitAlert(true, 'warning', message)
          : this.cancelGroupAlert = true
      }
      this.$emit('close', this.updateItems)
    },
    updateAddedItems (added, removed) {
      const itemsToInclude = added.filter(i => !this.isAdded(i))
      this.addedItems.push(...itemsToInclude)
      if (removed.length > 0) {
        for (const item of removed) {
          this.itemSelected(false, item)
        }
      }
    },
    itemSelected (value, item) {
      if (value === true) {
        if (!this.isAdded(item)) {
          this.addedItems.push(item)
        }
      } else {
        const index = this.addedItems.findIndex(p => {
          return (p?.item_id === item.item_id) && (p?.source === item.source)
        })
        if (index > -1) this.addedItems.splice(index, 1)
      }
    },
    isAdded (item) {
      return this.addedItems.some(i => {
        return (i?.item_id === item.item_id) && (i?.source === item.source)
      })
    },
    groupSaved (group) {
      this.currentGroup = this.getFormattedGroup(group, this.isAccrualPromo)
      if (!this.updateItems) this.updateItems = true
    },
    updateLists () {
      this.$emit('updateHistory')
      this.$emit('close', true)
    },
    async addItems () {
      this.loading = true
      const promises = this.addedItems.map(item => {
        const payload = this.buildPromoItemPayload(item)
        return PromoItem.post(payload)
      })
      try {
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        this.$emit('updateHistory')
        this.$emit('close', true)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    buildPromoItemPayload (item) {
      const itemGroupId = this.currentGroup.id
      let payload = {
        promo_item_group_id: itemGroupId,
        item_id: item.item_id,
        include_item: true,
        source: item.source
      }
      return payload
    },
    async deleteGroup () {
      this.deleting = true
      try {
        await PromoItemGroup.deletePromoItemGroup(this.currentGroup.id)
        this.$emit('close', false)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.deleting = false
      }
    }
  }
}
</script>
<style>
.modal-content-container {
  display: grid;
  grid-template-columns: minmax(auto, 290px) 1fr;
  grid-auto-flow: column;
  position: relative;
}

.modal-column {
  display: grid;
  width: 100%;
  padding: 0 12px 12px 12px;
}
</style>