var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pt-0 pb-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { ref: "form", attrs: { "lazy-validation": "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "px-2 pt-4 pb-0",
              attrs: { dense: "", align: "center" }
            },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Group Name",
                      rules: [
                        function(v) {
                          return !!v || "Group name is required"
                        }
                      ],
                      dense: "",
                      disabled: _vm.readOnlyPromo,
                      "validate-on-blur": "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.promoItemGroup.name,
                      callback: function($$v) {
                        _vm.$set(_vm.promoItemGroup, "name", $$v)
                      },
                      expression: "promoItemGroup.name"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.currentFields, function(field, i) {
                return _c(
                  "v-col",
                  { key: i },
                  [
                    field.isBoolean
                      ? _c("v-checkbox", {
                          staticClass: "my-auto",
                          attrs: {
                            label: field.name,
                            "hide-details": "auto",
                            dense: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.setGetItemAllowance(_vm.promoItemGroup)
                            }
                          },
                          model: {
                            value: _vm.promoItemGroup["" + field.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.promoItemGroup,
                                "" + field.value,
                                $$v
                              )
                            },
                            expression: "promoItemGroup[`${field.value}`]"
                          }
                        })
                      : field.isInteger
                      ? _c("v-text-field", {
                          attrs: {
                            label: field.name,
                            type: "number",
                            min: "0",
                            step: "1",
                            suffix: field.suffix,
                            "background-color": "#fff",
                            dense: "",
                            disabled: _vm.readOnlyPromo,
                            autocomplete: "off",
                            outlined: ""
                          },
                          model: {
                            value: _vm.promoItemGroup["" + field.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.promoItemGroup,
                                "" + field.value,
                                $$v
                              )
                            },
                            expression: "promoItemGroup[`${field.value}`]"
                          }
                        })
                      : field.isPercent
                      ? _c("v-text-field", {
                          attrs: {
                            label: field.name,
                            type: "number",
                            min: "0",
                            step: "1",
                            suffix: "%",
                            rules: [_vm.isValidPercent],
                            "background-color": "#fff",
                            dense: "",
                            disabled: _vm.readOnlyPromo,
                            autocomplete: "off",
                            outlined: ""
                          },
                          model: {
                            value: _vm.promoItemGroup["" + field.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.promoItemGroup,
                                "" + field.value,
                                $$v
                              )
                            },
                            expression: "promoItemGroup[`${field.value}`]"
                          }
                        })
                      : _c("v-text-field", {
                          attrs: {
                            label: field.name,
                            disabled:
                              _vm.overrideGroupValues || _vm.readOnlyPromo,
                            type: "number",
                            min: "0",
                            step: ".01",
                            prefix: "$",
                            rules:
                              field.value === "promotion_price"
                                ? _vm.promoPriceRules
                                : _vm.getPriceRules(field),
                            error: _vm.showErrorState(
                              field,
                              _vm.promoItemGroup
                            ),
                            "background-color": "#fff",
                            dense: "",
                            autocomplete: "off",
                            outlined: ""
                          },
                          model: {
                            value: _vm.promoItemGroup["" + field.value],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.promoItemGroup,
                                "" + field.value,
                                $$v
                              )
                            },
                            expression: "promoItemGroup[`${field.value}`]"
                          }
                        })
                  ],
                  1
                )
              }),
              _vm.isAccrualPromo
                ? _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Allowance Type",
                          items: _vm.accrualAllowanceTypes,
                          rules: [
                            function(v) {
                              return !!v || "Please select an allowance type"
                            }
                          ],
                          "menu-props": { offsetY: true },
                          outlined: "",
                          disabled: _vm.readOnlyPromo,
                          dense: ""
                        },
                        model: {
                          value: _vm.promoItemGroup.allowance_amt_type,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.promoItemGroup,
                              "allowance_amt_type",
                              $$v
                            )
                          },
                          expression: "promoItemGroup.allowance_amt_type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "ml-auto mb-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 white--text",
                                    attrs: {
                                      loading: _vm.loading,
                                      disabled:
                                        _vm.loading || _vm.readOnlyPromo,
                                      fab: "",
                                      small: "",
                                      elevation: "2",
                                      color: "green"
                                    },
                                    on: { click: _vm.saveGroup }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.newGroup
                                            ? " mdi-content-save-outline"
                                            : "mdi-update"
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.newGroup ? "Create" : "Update") + " Group"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }