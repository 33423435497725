var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        width: "90%",
        "max-width": "1900px",
        persistent: ""
      }
    },
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            top: "",
            "content-class": "pa-0",
            color: "warning",
            timeout: -1
          },
          model: {
            value: _vm.cancelGroupAlert,
            callback: function($$v) {
              _vm.cancelGroupAlert = $$v
            },
            expression: "cancelGroupAlert"
          }
        },
        [
          _c(
            "v-alert",
            {
              staticClass: "my-0",
              attrs: {
                dense: "",
                border: "left",
                prominent: "",
                type: "warning",
                "retain-focus": true
              },
              model: {
                value: _vm.cancelGroupAlert,
                callback: function($$v) {
                  _vm.cancelGroupAlert = $$v
                },
                expression: "cancelGroupAlert"
              }
            },
            [
              _vm._v(" Cancel creating this promo item group? "),
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      attrs: {
                        outlined: "",
                        loading: _vm.deleting,
                        disabled: _vm.deleting
                      },
                      on: { click: _vm.deleteGroup }
                    },
                    [_vm._v(" Yes ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.deleting },
                      on: {
                        click: function($event) {
                          _vm.cancelGroupAlert = false
                        }
                      }
                    },
                    [_vm._v(" No ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "white--text",
              attrs: { flat: "", color: "accent" }
            },
            [
              _c("v-toolbar-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentGroup ? "Item Search" : "New Item Group"
                    ) +
                    " "
                )
              ]),
              _c("v-spacer"),
              _c("v-col", { attrs: { cols: "3" } }),
              _c(
                "v-card-actions",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "", text: "" },
                      on: { click: _vm.onClose }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ItemGroupEdit", {
            ref: "groupEdit",
            attrs: {
              itemGroup: _vm.currentGroup,
              promo_type_fields: _vm.promo_type_fields,
              promo_type_constant: _vm.promo_type_constant,
              promo: _vm.promo,
              readOnlyPromo: _vm.readOnlyPromo
            },
            on: { saved: _vm.groupSaved }
          }),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "div",
                { staticClass: "modal-content-container" },
                [
                  _c(
                    "v-overlay",
                    {
                      attrs: {
                        value: !_vm.currentGroup,
                        absolute: _vm.absolute
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { fab: "", "x-small": "", color: "white" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "orange", "x-large": "" } },
                                [_vm._v(" mdi-alert-circle ")]
                              )
                            ],
                            1
                          ),
                          _c("h3", [_vm._v("Save Group Details to Add Items")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-column" },
                    [
                      _c("BulkItemSearch", {
                        attrs: {
                          source_ids: _vm.item_sources,
                          vmc_sources: _vm.vmc_sources
                        },
                        model: {
                          value: _vm.bulkSearchPayload,
                          callback: function($$v) {
                            _vm.bulkSearchPayload = $$v
                          },
                          expression: "bulkSearchPayload"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mt-auto mb-0",
                          staticStyle: { display: "grid" }
                        },
                        [
                          _vm.addedItems.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "mb-2",
                                  staticStyle: {
                                    display: "grid",
                                    "grid-auto-flow": "column"
                                  }
                                },
                                [
                                  _c("v-subheader", { staticClass: "ma-0" }, [
                                    _vm._v(
                                      " Items selected: " +
                                        _vm._s(_vm.uniqueItems.length) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "my-auto mr-2",
                                      attrs: {
                                        small: "",
                                        disabled: _vm.loading
                                      },
                                      on: { click: _vm.clearItems }
                                    },
                                    [_vm._v(" Clear ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.readOnlyPromo
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mt-auto mb-2 mx-2",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading,
                                    disabled:
                                      _vm.loading || _vm.addedItems.length === 0
                                  },
                                  on: { click: _vm.addItems }
                                },
                                [_vm._v(" Add Items ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("ItemSearchTable", {
                    ref: "itemSearch",
                    attrs: {
                      item_group: _vm.currentGroup,
                      item_search: _vm.filter,
                      item_sources: _vm.item_sources,
                      panel_height: _vm.panel_height,
                      promoItems: _vm.promoItems,
                      addedItems: _vm.addedItems,
                      vendorIds: _vm.vendorIds,
                      bulkSearchPayload: _vm.bulkSearchPayload,
                      promo: _vm.promo,
                      readOnlyPromo: _vm.readOnlyPromo
                    },
                    on: {
                      selected: _vm.itemSelected,
                      updateAdded: _vm.updateAddedItems,
                      updateLists: _vm.updateLists
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }