<template>
  <v-container fluid class="ma-0 pa-0">
    <v-data-table
      :height="tableSize"
      :headers="headers"
      :loading="loading"
      :items.sync="items"
      :no-data-text="(!item_group) ? 'Please create a group' : 'Please enter a UPC or item id'"
      fixed-header
      style="border-left: 1px solid #ddd"
      :items-per-page="-1"
      :footer-props="{'items-per-page-options': [-1]}"
      group-by="description"
      :custom-group="groupItemsFn"
      dense>

      <template v-slot:no-data>
        <v-card flat v-if="!loading && bulkSearchPayload">
          <v-card-text>
            <v-container>
              <div style="padding-top: 18px">
                <p>No results found matching your search:<br>
                  <span class="font-weight-bold">
                    "{{ bulkSearchPayload.values.join(', ') }}"
                  </span>
                </p>
                <p>
                  Item sources:
                  <span class="font-weight-bold">
                    {{ bulkSearchSources }}
                  </span>
                  <br>
                  <template v-if="vendorIds.length > 0">
                    Vendor IDs on promotion:
                    <span class="font-weight-bold">
                      {{ vendorIds.join(', ') }}
                    </span>
                  </template>
                </p>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:[`group.header`]="{ group, items, headers, toggle, isOpen }">
        <td colspan="1">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
        <td colspan="1">
          <div class="group-actions-container">
            <v-simple-checkbox v-if="!readOnlyPromo"
              color="primary"
              v-model="tableGroups[group].selected"
              @input="tableGroupSelected($event, group)"
              :disabled="items.every(i => i.included)"
              dense
              :ripple="false">
            </v-simple-checkbox>
          </div>
        </td>
        <td colspan="3">
          {{ group }}
        </td>
        <td colspan="1" class="mx-0 px-0">
          <span v-if="!isOpen && groupHasLinkedItemsAdded(items)">
            <v-icon>mdi-link-variant</v-icon>
          </span>
        </td>
        <td :colspan="(headers.length - 4)"></td>
      </template>

      <template v-slot:[`header.expanded`]>
        <v-btn @click="toggleAll" :disabled="(items.length === 0)" dark small icon>
          <v-icon v-if="anyGroupsExpanded">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`header.included`]>
        <v-simple-checkbox
          dark
          :disabled="(items.length === 0 || includeAllDisabled)"
          v-model="includeAll"
          dense
          :ripple="false">
        </v-simple-checkbox>
      </template>

      <template v-slot:[`item.included`]="{ item }">
        <v-simple-checkbox
          v-if="!isIncludedOnPromo(item)"
          color="primary"
          v-model="item.selected"
          @input="itemSelected($event, item)"
          dense
          :ripple="false">
        </v-simple-checkbox>
        <v-icon v-else color="success">
          mdi-check
        </v-icon>
      </template>

      <template v-slot:[`item.related`]="{ item }">
        <div v-if="item[tenantLinkedProperty]" style="margin-left: 15px">
          <v-tooltip class="mx-auto" top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                color="primary"
                @click="viewRelatedItems(item)">
                <v-icon class="mx-auto">mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>Related Items</span>
          </v-tooltip>
          <span v-if="hasLinkedItemsAdded(item)">
            <v-icon
              class="ml-3"
              color="primary"
              @click="viewRelatedItems(item)">
              mdi-link-variant
            </v-icon>
          </span>
        </div>
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        <v-tooltip top :disabled="item.cost == item.effective_cost">
          <template v-slot:activator="{ on }">
            <span 
              v-on="on"
              v-bind:class="(item.cost != item.effective_cost) ? 'primary--text font-weight-bold' : ''">
              {{item.effective_cost}}
            </span>
          </template>
          This item has a cost change on {{ $config.formatDate(item.future_cost_date) }} <br>
          The current cost is {{ item.cost }} <br>
          This promotion will use the new cost of {{ item.effective_cost }}
        </v-tooltip>
      </template>
    </v-data-table>
    <RelatedItemsModal
      v-if="showRelatedItemsModal"
      v-model="showRelatedItemsModal"
      :promoItem="currentItem"
      :promoItemGroup="item_group"
      :item_sources="item_sources"
      :promoItems="promoItems"
      :addedItems="addedItems"
      :tableItems="items"
      :readOnlyPromo="readOnlyPromo"
      :vendorIds="vendorIds"
      :promo="promo"
      @close="updateLists"
      @updateAdded="updateAddedItems"
      @cancel="showRelatedItemsModal = false">
    </RelatedItemsModal>
  </v-container>
</template>
<script>
// api
// import Es from '@/axios/es'
import Items from '@/axios/items-endpoint'

// mixins
// import { buildElasticQuery } from '@/mixins/es_querybuilder'
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'

// components
const RelatedItemsModal = () => import('@/components/items/RelatedItemsModal.vue')
export default {
  name: 'item-search',
  data () {
    return {
      items: [],
      showRelatedItemsModal: false,
      currentItem: null,
      includeAll: false,
      tableGroups: {}
    }
  },
  mixins: [fullWidthTable, utils, displayAlert],
  props: {
    item_sources: Array,
    item_group: Object,
    item_search: String,
    promoItems: Array,
    addedItems: Array,
    vendorIds: {
      type: Array,
      default: () => ([])
    },
    bulkSearchPayload: Object,
    promo: Object,
    readOnlyPromo: {
      type: Boolean
    }
  },
  components: { RelatedItemsModal },
  watch: {
    includeAll: {
      handler (newValue) {
        if (newValue === false && this.availableItems.some(item => !this.isSelected(item))) {
          return
        }
        this.items = this.items.flatMap(item => {
          if (!this.isIncludedOnPromo(item)) {
            item.selected = newValue
            this.itemSelected(newValue, item)
          }
          return item
        })
      }
    },
    bulkSearchPayload: {
      handler (newValue, oldValue) {
        if (newValue === null && oldValue) {
          return this.initItems()
        }
        this.searchItems()
      },
      deep: true
    },
    bulkSearch: {
      handler (newValue) {
        if (newValue === true) this.items = []
      }
    },
    item_group: {
      handler: 'initItems',
      deep: true
    },
    item_search () {
      this.search = this.item_search
    },
    search: {
      handler (newValue) {
        if (newValue?.length > 0 && !this.loading) {
          this.loading = true
        }
      }
    },
    item_sources: {
      handler (newValue) {
        if (newValue?.length > 0) {
          this.initItems()
        }
      },
      deep: true
    },
    vendorIds: {
      handler (newValue) {
        if (newValue) {
          this.initItems()
        }
      },
      deep: true
    }
  },
  created () {
    this.nonTableHeight = this.panel_height || 333
  },
  computed: {
    headers() {
      const headers = [
        { sortable: false, filterable: false, value: 'expanded', class: 'accent white--text' },
        { sortable: false, filterable: false, value: 'included', class: 'accent white--text' },
        { text: 'Source', sortable: false, value: 'source_name', class: 'accent white--text' },
        { text: 'Item Id', sortable: false, divider: false, value: 'item_id', class: 'accent white--text' },
        { text: 'Name', sortable: false, value: 'description', class: 'accent white--text' },
        { text: 'UPC', sortable: false, value: 'upc', class: 'accent white--text' },
        { text: 'Vendor Id', sortable: false, value: 'vendor_id', class: 'accent white--text' },
        { text: 'Vendor Name', value: 'vendor_name', class: 'accent white--text' },
        { text: 'Retail Price', sortable: false, value: 'sale_price', class: 'accent white--text' },
        { text: 'Cost', sortable: false, value: 'cost', class: 'accent white--text' },
        { text: 'Pack Size', sortable: false, value: 'pack_size', class: 'accent white--text' },
        { text: 'Related Items', value: 'related', sortable: false, class: 'accent white--text' },
        //  { text: 'Actions', value: 'actions', sortable: false, filterable: false, class: 'accent white--text' }
      ]
      if (this.readOnlyPromo === true) {
        return headers.filter(header => !['included', 'expanded'].includes(header.value))
      } else {
        return headers
      }
    },
    tenantLinkedProperty() {
      if (this.$auth.tenant === 'pricechopper') { 
        return 'pce_link_code'
      } else {
        return 'linked_group'
      }
    },
    bulkSearch () {
      return Boolean(this.bulkSearchPayload)
    },
    bulkSearchSources () {
      if (this.bulkSearchPayload?.sources?.length > 0) {
        const names = this.bulkSearchPayload.sources.map(s => {
          return this.$store.getters.getSourceName(s)
        })
        return names.join(', ')
      }
      return ''
    },
    groupItems () {
      return this.promoItems.filter(promoItem => {
        return promoItem?.promo_item_group?.id === this.item_group?.id
      })
    },
    availableItems () {
      return this.items.filter(item => !this.isIncludedOnPromo(item))
    },
    includeAllDisabled () {
      return (this.availableItems.length === 0)
    },
    isComplexBuyGet () {
      return this.promo.promotion_type.constant === 'BUY_X_GET_X' 
    },
    anyGroupsExpanded () {
      if (this.items.length > 0) {
        return Object.keys(this.$refs).some(k => {
          return (this.$refs[k] && this.$refs[k].$attrs['data-open'])
        })
      }
      return false
    }
  },
  methods: {
    updateAddedItems (itemChanges) {
      const { addedItems, removedItems } = itemChanges
      this.$emit('updateAdded', addedItems, removedItems)

      this.items = this.items.map(item => {
        const isAdded = addedItems.find(i => this.isSameItem(i, item))
        if (isAdded) {
          item.selected = true
        }
        return item
      })
    },
    isSameItem (item1, item2) {
      return (item1?.item_id === item2.item_id) && (item1?.source === item2.source)
    },
    hasLinkedItemsAdded (item) {
      return this.addedItems.some(addedItem => {
        return addedItem.linked_from 
          && this.isSameItem(addedItem.linked_from, item)
          && !(this.items.find(i => this.isSameItem(i, addedItem)))
      })
    },
    groupHasLinkedItemsAdded (groupItems) {
      return groupItems.some(groupItem => this.hasLinkedItemsAdded(groupItem))
    },
    toggleAll () {
      const keys = Object.keys(this.$refs)
      for (const k of keys) {
        if (this.$refs[k]) {
          this.handleGroupToggle(k)
        }
      }
    },
    handleGroupToggle (group) {
      const isOpen = this.$refs[group].$attrs['data-open']
      const shouldToggle = this.anyGroupsExpanded ? isOpen : !isOpen
      if (shouldToggle) {
        this.$refs[group].$el.click()
      }
    },
    itemSelected (value, item) {
      this.$emit('selected', value, item)
      if (value === false && this.includeAll) {
        this.includeAll = false
      }
    },
    viewRelatedItems (item) {
      this.currentItem = item
      this.showRelatedItemsModal = true
    },
    updateLists () {
      this.showRelatedItemsModal = false
      this.currentItem = null
      this.$emit('updateLists')
    },
    includeItem (item) {
      this.$emit('includeItem', item)
      item.included = true
    },
    initItems () {
      if (this.includeAll) this.includeAll = false
      this.items = []
      this.searchItems()
    },
    async searchItems () {
      if (this.bulkSearch) {
        const payload = this.bulkSearchPayload
        return this.bulkSearchItems(payload)
      }
      /*
      this.loading = true
      const query = this.buildItemSearchQuery()
      const postObj = {
        body: {
          from: this.from,
          size: this.pageSize,
          query,
          sort: [{
            'item_id.keyword': {order: 'asc'}
          }]
        }
      }
      let items = []
      try {
        const res = await Es.post('ITEM', '/_search?rest_total_hits_as_int', postObj)
        if (res?.hits?.hits?.length > 0) {
          items = res.hits.hits.flatMap(result => {
            const item = result._source
            item.uuid = result._id
            return this.formatItem(item)
          })
          this.serverItemsLength = res.hits.total
        }
        this.items = items
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
      */
    },
    async bulkSearchItems (payload) {
      if (this.vendorIds?.length > 0) {
        payload.vendor_ids = this.vendorIds
      }
      this.loading = true
      let items = []
      try {
        for await (let results of Items.searchItems(payload)) {
          if (results?.length > 0) {
            results = results.map(item => this.formatItem(item))
            items.push(...results)
          }
        }
        this.items = items
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatItem (item) {
      item.effective_cost = this.calcEffectiveCost(item, this.promo)
      item.selected = this.isSelected(item)
      item.included = this.isIncludedOnPromo(item)
      item.source_name = this.$store.getters.getSourceName(item.source)
      item = this.formatItemPrices(item)
      return item
    },
    groupItemsFn (items) {
      const key = 'description'
      // base for loop below came from Vuetify's default groupItems function
      // all I'm doing is allowing groups to be "selectable"
      let groups = []
      let current
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const val = item[key]
        if (current !== val) {
          current = val
          groups.push({
            name: val ?? '',
            items: [],
            // selected key doesn't exist normally
            selected: false
          })
        }
        groups[groups.length - 1].items.push(item)
      }
      // updating the value after it has all its items
      groups = groups.map(group => {
        if (group.items.every(item => (item.selected === true || item.included === true))) {
          group.selected = true
        }
        return group
      })
      this.setTableGroups(groups)
      return groups
    },
    setTableGroups (groups) {
      // need to restructure into an object to update from v-slot
      const groupMap = groups.map(group => {
        const { name, ...rest } = group
        return [name, { ...rest }]
      })
      this.tableGroups = Object.fromEntries(groupMap)
    },
    tableGroupSelected (val, groupName) {
      this.items = this.items.map(item => {
        if (!item.included && item.description === groupName) {
          item.selected = val
          this.itemSelected(val, item)
        }
        return item
      })
    },
    /*
    buildItemSearchQuery () {
      const query = cloneDeep(this.buildElasticQuery('ITEM'))
      query.bool.filter.push({
        terms: { 'source.keyword': this.item_sources }
      })
      if (this.vendorIds?.length > 0) {
        query.bool.filter.push({
          terms: { 'vendor_id.keyword': this.vendorIds }
        })
      }
      return query
    },
    */
    isSelected (item) {
      return Boolean(this.addedItems.find(addedItem => this.isSameItem(addedItem, item)))
    },
    isIncludedOnPromo (item) {
      return this.promoItems.some(promoItem => {
        const { item_id, source } = promoItem.item
        const item_on_promo = ((item_id === item.item_id) && (source === item.source))
        // commenting out for now until we know this won't break billing
        /*
        const complex_item_exists = ((promoItem.promo_item_group.buy_item === this.item_group.buy_item)
            || (promoItem.promo_item_group.get_item === this.item_group.get_item))
        return this.isComplexBuyGet ? (item_on_promo && complex_item_exists) : item_on_promo
        */
        return item_on_promo
      })
    },
    openItemPortal () {
      let baseUrl = process.env.VUE_APP_ITEM_PORTAL
      let prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        baseUrl = `${prefix}.${baseUrl}`
      }
      const url = `https://${baseUrl}/items/edit`
      window.open(url, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.searchSnippet {
  margin: 5px 20px,
}
.capitalize {
  text-transform: capitalize;
}
::v-deep tbody tr.v-data-table__expanded__content {
  background: #efefef;
  box-shadow: none !important;
}
</style>