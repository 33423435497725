var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticStyle: { "border-left": "1px solid #ddd" },
        attrs: {
          height: _vm.tableSize,
          headers: _vm.headers,
          loading: _vm.loading,
          items: _vm.items,
          "no-data-text": !_vm.item_group
            ? "Please create a group"
            : "Please enter a UPC or item id",
          "fixed-header": "",
          "items-per-page": -1,
          "footer-props": { "items-per-page-options": [-1] },
          "group-by": "description",
          "custom-group": _vm.groupItemsFn,
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.items = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function() {
                return [
                  !_vm.loading && _vm.bulkSearchPayload
                    ? _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-container", [
                                _c(
                                  "div",
                                  { staticStyle: { "padding-top": "18px" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "No results found matching your search:"
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            ' "' +
                                              _vm._s(
                                                _vm.bulkSearchPayload.values.join(
                                                  ", "
                                                )
                                              ) +
                                              '" '
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "p",
                                      [
                                        _vm._v(" Item sources: "),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.bulkSearchSources) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("br"),
                                        _vm.vendorIds.length > 0
                                          ? [
                                              _vm._v(
                                                " Vendor IDs on promotion: "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.vendorIds.join(", ")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var items = ref.items
                var headers = ref.headers
                var toggle = ref.toggle
                var isOpen = ref.isOpen
                return [
                  _c(
                    "td",
                    { attrs: { colspan: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          ref: group,
                          attrs: { small: "", icon: "", "data-open": isOpen },
                          on: { click: toggle }
                        },
                        [
                          isOpen
                            ? _c("v-icon", [_vm._v("mdi-chevron-up")])
                            : _c("v-icon", [_vm._v("mdi-chevron-down")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("td", { attrs: { colspan: "1" } }, [
                    _c(
                      "div",
                      { staticClass: "group-actions-container" },
                      [
                        !_vm.readOnlyPromo
                          ? _c("v-simple-checkbox", {
                              attrs: {
                                color: "primary",
                                disabled: items.every(function(i) {
                                  return i.included
                                }),
                                dense: "",
                                ripple: false
                              },
                              on: {
                                input: function($event) {
                                  return _vm.tableGroupSelected($event, group)
                                }
                              },
                              model: {
                                value: _vm.tableGroups[group].selected,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.tableGroups[group],
                                    "selected",
                                    $$v
                                  )
                                },
                                expression: "tableGroups[group].selected"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(" " + _vm._s(group) + " ")
                  ]),
                  _c(
                    "td",
                    { staticClass: "mx-0 px-0", attrs: { colspan: "1" } },
                    [
                      !isOpen && _vm.groupHasLinkedItemsAdded(items)
                        ? _c(
                            "span",
                            [_c("v-icon", [_vm._v("mdi-link-variant")])],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("td", { attrs: { colspan: headers.length - 4 } })
                ]
              }
            },
            {
              key: "header.expanded",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.items.length === 0,
                        dark: "",
                        small: "",
                        icon: ""
                      },
                      on: { click: _vm.toggleAll }
                    },
                    [
                      _vm.anyGroupsExpanded
                        ? _c("v-icon", [_vm._v("mdi-chevron-up")])
                        : _c("v-icon", [_vm._v("mdi-chevron-down")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "header.included",
              fn: function() {
                return [
                  _c("v-simple-checkbox", {
                    attrs: {
                      dark: "",
                      disabled:
                        _vm.items.length === 0 || _vm.includeAllDisabled,
                      dense: "",
                      ripple: false
                    },
                    model: {
                      value: _vm.includeAll,
                      callback: function($$v) {
                        _vm.includeAll = $$v
                      },
                      expression: "includeAll"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.included",
              fn: function(ref) {
                var item = ref.item
                return [
                  !_vm.isIncludedOnPromo(item)
                    ? _c("v-simple-checkbox", {
                        attrs: { color: "primary", dense: "", ripple: false },
                        on: {
                          input: function($event) {
                            return _vm.itemSelected($event, item)
                          }
                        },
                        model: {
                          value: item.selected,
                          callback: function($$v) {
                            _vm.$set(item, "selected", $$v)
                          },
                          expression: "item.selected"
                        }
                      })
                    : _c("v-icon", { attrs: { color: "success" } }, [
                        _vm._v(" mdi-check ")
                      ])
                ]
              }
            },
            {
              key: "item.related",
              fn: function(ref) {
                var item = ref.item
                return [
                  item[_vm.tenantLinkedProperty]
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "15px" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "mx-auto",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.viewRelatedItems(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mx-auto" },
                                              [_vm._v("mdi-view-list")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("Related Items")])]
                          ),
                          _vm.hasLinkedItemsAdded(item)
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewRelatedItems(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" mdi-link-variant ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.cost",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled: item.cost == item.effective_cost
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      class:
                                        item.cost != item.effective_cost
                                          ? "primary--text font-weight-bold"
                                          : ""
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.effective_cost) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(
                        " This item has a cost change on " +
                          _vm._s(
                            _vm.$config.formatDate(item.future_cost_date)
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" The current cost is " + _vm._s(item.cost) + " "),
                      _c("br"),
                      _vm._v(
                        " This promotion will use the new cost of " +
                          _vm._s(item.effective_cost) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.showRelatedItemsModal
        ? _c("RelatedItemsModal", {
            attrs: {
              promoItem: _vm.currentItem,
              promoItemGroup: _vm.item_group,
              item_sources: _vm.item_sources,
              promoItems: _vm.promoItems,
              addedItems: _vm.addedItems,
              tableItems: _vm.items,
              readOnlyPromo: _vm.readOnlyPromo,
              vendorIds: _vm.vendorIds,
              promo: _vm.promo
            },
            on: {
              close: _vm.updateLists,
              updateAdded: _vm.updateAddedItems,
              cancel: function($event) {
                _vm.showRelatedItemsModal = false
              }
            },
            model: {
              value: _vm.showRelatedItemsModal,
              callback: function($$v) {
                _vm.showRelatedItemsModal = $$v
              },
              expression: "showRelatedItemsModal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }