<template>
  <v-form ref="form" lazy-validation>
    <v-container class="mt-0 pt-0">
    <!--  <v-row class="mb-1">
        <v-col class="mt-0 pt-0">
          <v-select
            v-model="selectedSources"
            label="Item Sources"
            :menu-props="{ offsetY: true }"
            :items="itemSourceOptions"
            item-text="source_name"
            item-value="source_id"
            hide-details="auto"
            multiple
            small-chips
            deletable-chips
            :rules="[v => (!!v && v.length > 0) || 'A minimum of one item source is required']"
            outlined
            dense>
          </v-select>
        </v-col>
      </v-row> -->
      <v-subheader class="ma-0 pa-0" style="height: 28px">
        UPC/Item ID Lookup
      </v-subheader>
      <v-row class="mt-0 pt-0">
        <v-col style="padding-bottom: 8px">
          <v-textarea
            v-model="searchInput"
            hint="Enter UPCs/item IDs on separate lines"
            persistent-hint
            :rules="[v => !!v || 'At least one UPC or item ID is required']"
            rows="6"
            outlined
            dense>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row dense class="mt-0 mb-1 pt-0 align-center">
        <v-checkbox
          class="ml-1 mr-2 mt-0"
          label="UPC"
          color="primary"
          v-model="upc_search"
          @change="$refs.item_id_search.validate()"
          ref="upc_search"
          dense
          :rules="[atLeastOneTrue]"
          :ripple="false">
        </v-checkbox>
        <v-checkbox
          class="ml-4 mr-0 mt-0"
          label="Item ID"
          color="primary"
          v-model="item_id_search"
          @change="$refs.upc_search.validate()"
          ref="item_id_search"
          dense
          :rules="[atLeastOneTrue]"
          :ripple="false">
        </v-checkbox>
      </v-row>
      <v-btn @click="submitSearch">
        Search
      </v-btn>
      <v-btn color="grey darken-1" v-if="searchInput" text @click="clear">
        Clear
      </v-btn>
    </v-container>
  </v-form>
</template>
<script>
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      selectedSources: [],
      searchInput: '',
      upc_search: true,
      item_id_search: true
    }
  },
  name: 'BulkItemSearch',
  mixins: [displayAlert, userAccess],
  props: {
    source_ids: Array,
    vmc_sources: Array,
    value: Object
  },
  created () {
    if (this.source_ids.length > 0) {
      this.selectedSources.push(...this.source_ids)
    }
  },
  computed: {
    sources () {
      return this.$store.getters.sources
    },
    itemSourceOptions () {
      const sources = this.source_ids.flatMap(id => {
        const match = this.sources.find(s => s.source.source_id === id)
        return match?.source || []
      })
      return sources.sort((a, b) => a.source_name - b.source_name)
    }
  },
  methods: {
    atLeastOneTrue () {
      if (this.upc_search || this.item_id_search) return true
      return false
    },
    submitSearch () {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
      const normalized = this.searchInput.split(/\r?\n/).flatMap(v => v ? v.trim() : [])
      const validValues = this.validateInputValues(normalized)
      if (!validValues) {
        return this.emitAlert(true, 'warning', 'Please make sure your search values contain only valid UPC/item ID characters and are at least 4 characters in length')
      }
      const payload = {
        sources: [...this.selectedSources],
        values: normalized,
        item_id_search: this.item_id_search,
        upc_search: this.upc_search
      }
      if (this.vmc_sources?.length > 0) {
        payload.vmc_sources = this.vmc_sources
      }
      this.$emit('input', payload)
    },
    validateInputValues (values) {
      if (this.tenant === 'pricechopper') {
        return values.every(val => {
          const validChars = /^[A-Za-z]*[0-9]+$/.test(val)
          return (validChars && val.length >= 4)
        })
      }
      return values.every(val => {
        const validChars = /^[D0-9]*$/.test(val)
        return (validChars && val.length >= 4)
      })
    },
    clear () {
      this.resetAlert()
      this.$refs.form.resetValidation()
      this.searchInput = ''
      this.$emit('input', null)
    }
  }
}
</script>